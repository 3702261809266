import React, { Component } from "react";
import * as Styled from "../../styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./style.css";
import Brian from "assets/images/Brian.png";
// import JasonCook from 'assets/images/Jason-Cook.png';
//import MikeJohnson from 'assets/images/mike-johnson.png';
import JoshCavalier from "assets/images/josh-cavalier.png";
// import SteveHenriod from 'assets/images/steve-henriod.png';
import JessicaBurrola from "assets/images/Jessica-Burrola.png";
//import ErickChavarria from 'assets/images/Erick-Chavarria.png';
// import LandanReiss from "assets/images/Landan_Reiss.png";
// import JaredKendrick from "assets/images/Jared_Kendrick.jpg";
import EmilClark from "assets/images/Emil_Clark.png";
const agentsOptions = [
  // {
  //   name: 'Erick Chavarria',
  //   img: ErickChavarria,
  //   text: 'Utah, Summit, Wasatch, Washington Counties'
  // },
  // {
  //   name: "Jared Kendrick",
  //   img: JaredKendrick,
  //   text: "Salt Lake, Summit, Wasatch and Duchesne Counties",
  // },
  {
    name:"Emil Clark",
    img: EmilClark,
    text: "Utah, Summit, Wasatch, Duchesne Counties",

  },
  {
    name: "Jessica Burrola",
    img: JessicaBurrola,
    text: "Davis, Weber and Cache Counties",
  },
  // {
  //   name: 'Steve Henriod',
  //   img: SteveHenriod,
  //   text: 'Box Elder and Tooele Counties'
  // },
  // {
  //   name: 'Jason Cook',
  //   img: JasonCook,
  //   text: 'Utah, Summit, Wasatch, Washington Counties'
  // },
  {
    name: "Brian Miyasaki",
    img: Brian,
    text: "Salt Lake, Utah, Washington Counties",
  },
  // {
  //   name: "Josh Cavalier",
  //   img: JoshCavalier,
  //   text: "County Wanderer",
  // },
];

function Agent(props) {
  return (
    <div
      className={"agent" + (props.selected ? " selected" : "")}
      onClick={props.onClick}
    >
      <h3 className="agent__name">{props.name}</h3>
      <div className="agent__img-container">
        <img src={props.img} alt={props.name} className="agent__img" />
      </div>
      <p
        className="agent__text"
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></p>
    </div>
  );
}

class AreaManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentsOptions: agentsOptions,
    };
  }
  onAgentSelected = (agent) => {
    const { agentsOptions } = this.state;
    const { onAgentSelect } = this.props;
    agentsOptions.forEach((agentItem) => {
      agentItem.selected = agentItem.name === agent.name;
    });
    this.setState({ agentsOptions });
    if (onAgentSelect) {
      onAgentSelect(agent);
    }
  };
  render() {
    const { agentsOptions } = this.state;
    const { t } = this.props;
    return (
      <Styled.FormGroup>
        <Typography
          className="label"
          color="primary"
          align="left"
          variant="subtitle"
          sx={{
            fontWeight: "700",
            textAlign: "left !important",
            fontFamily: "'PT Sans Narrow', 'Roboto', 'sans-serif'",
          }}
          justifyContent="start"
        >
          {t("plans:selectManager")}
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          {agentsOptions.map((agent, key) => (
            <Grid item xs={12} sm={2} key={key}>
              <Agent {...agent} onClick={() => this.onAgentSelected(agent)} />
            </Grid>
          ))}
        </Grid>
      </Styled.FormGroup>
    );
  }
}

export default AreaManager;
