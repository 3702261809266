import React from 'react';

export default () => (
    <React.Fragment>
        <h1>Términos y Condiciones</h1>
        <p>
            <strong>
                Fecha de entrada en vigor:
            </strong>
            &nbsp;3 de septiembre de 2024
        </p>
        <ol>
            <li>
                <h3>
                    Introducción
                </h3>
                <p>
                    Bienvenido al sitio web de SilverBack Home Warranty (el “Sitio”). Al acceder o utilizar este Sitio,
                    usted acepta cumplir y estar sujeto a estos Términos y Condiciones (“Términos”). Le recomendamos
                    leer estos Términos cuidadosamente antes de usar el Sitio.
                </p>
            </li>
            <li>
                <h3>
                    Uso del Sitio
                </h3>
                <p>
                    Al acceder al Sitio, usted se compromete a usarlo únicamente para fines legales y de una manera que
                    no infrinja los derechos de otros ni restrinja o inhiba su uso y disfrute del Sitio. El uso no
                    autorizado de este Sitio puede dar lugar a reclamaciones por daños y/o constituir un delito.
                </p>
            </li>
            <li>
                <h3>
                    Propiedad Intelectual
                </h3>
                <p>
                    Todo el contenido del Sitio, incluyendo, pero no limitado a, textos, gráficos, logotipos, imágenes y
                    software, es propiedad de SilverBack Home Warranty o de sus proveedores de contenido y está
                    protegido por las leyes de propiedad intelectual. Está prohibida la reproducción o uso no autorizado
                    del contenido sin consentimiento previo por escrito.
                </p>
            </li>
            <li>
                <h3>
                    Pedido de Servicios
                </h3>
                <p>
                    Al solicitar servicios a través de nuestro Sitio, deberá proporcionar información precisa y
                    completa. Cualquier información falsa o engañosa puede llevar a la cancelación del pedido. Los
                    precios de los servicios están sujetos a cambios sin previo aviso, y los cambios se reflejarán en el
                    Sitio en el momento del pedido. Al realizar un pedido a través del Sitio, usted acepta pagar las
                    tarifas indicadas para los servicios seleccionados.
                </p>
            </li>
            <li>
                <h3>
                    Privacidad
                </h3>
                <p>
                    El uso del Sitio está sujeto a nuestra Política de Privacidad, que detalla cómo recopilamos, usamos
                    y protegemos su información personal. Al utilizar el Sitio, usted acepta las prácticas descritas en
                    la Política de Privacidad.
                </p>
            </li>
            <li>
                <h3>
                    Enlaces a Terceros
                </h3>
                <p>
                    El Sitio puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan solo para su
                    conveniencia, y SilverBack Home Warranty no respalda ni se hace responsable del contenido o las
                    prácticas de dichos sitios. El acceso a estos enlaces es bajo su propio riesgo.
                </p>
            </li>
            <li>
                <h3>
                    Limitación de Responsabilidad
                </h3>
                <p>
                    SilverBack Home Warranty no garantiza que el Sitio esté libre de errores, interrupciones o virus u
                    otros componentes dañinos. No nos responsabilizamos de ningún daño derivado del uso del Sitio,
                    incluyendo, pero no limitado a, daños directos, indirectos, incidentales o consecuenciales.
                </p>
            </li>
            <li>
                <h3>
                    Cambios en el Sitio y en los Términos
                </h3>
                <p>
                    SilverBack Home Warranty se reserva el derecho de modificar o actualizar el Sitio y estos Términos
                    en cualquier momento sin previo aviso. Su uso continuado del Sitio tras cualquier cambio constituye
                    su aceptación de los Términos actualizados.
                </p>
            </li>
            <li>
                <h3>
                    Cuentas de Usuario
                </h3>
                <p>
                    Si crea una cuenta en nuestro Sitio, es responsable de mantener la confidencialidad de la
                    información de su cuenta y contraseña. Usted acepta la responsabilidad por todas las actividades que
                    ocurran bajo su cuenta.
                </p>
            </li>
            <li>
                <h3>
                    Ley Aplicable
                </h3>
                <p>
                    Estos Términos se regirán e interpretarán de acuerdo con las leyes del estado de Utah, sin tener en
                    cuenta sus principios de conflicto de leyes.
                </p>
            </li>

            <li>
                <h3>
                    Información de Contacto
                </h3>
                <p>
                    Para cualquier pregunta sobre estos Términos, contáctenos en:
                    <br/>
                    <strong> SilverBack Home Warranty </strong>
                    <br/>
                    <strong> Teléfono: </strong>: 833-750-6400
                    <br/>
                    <strong> Correo electrónico: </strong>: <a href="mailto:info@silverbackhw.com">info@silverbackhw.com</a>
                </p>
            </li>
        </ol>
    </React.Fragment>
);
