import React from "react";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

import AutoComplete from "components/common/auto-complete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export const renderCheckbox = ({ input, label, meta: { error }, ...rest }) => (
  <FormControl>
    {label && (
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={Boolean(input.value)}
            onChange={input.onChange}
            {...rest}
          />
        }
      />
    )}
    {!label && (
      <Checkbox
        checked={Boolean(input.value)}
        onChange={input.onChange}
        {...rest}
      />
    )}
    {error && <FormHelperText error>{error}</FormHelperText>}
  </FormControl>
);

export const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  inputRef,
  ...rest
}) => (
  <TextField
    fullWidth
    label={label}
    variant="standard"
    error={error && touched}
    helperText={touched && error}
    {...input}
    {...rest}
    ref={inputRef}
  />
);

export const renderRadioGroup = ({ input, meta, options, ...rest }) => (
  <FormControl fullWidth error={Boolean(meta.error)}>
    <RadioGroup
      {...input}
      {...rest}
      value={input.value}
      onChange={(event, value) => input.onChange(value)}
    >
      {options &&
        options.map(({ label, value, disabled }, index) => (
          <FormControlLabel
            key={`option-${index}`}
            classes={{
              label: disabled ? "disabled-field-label" : "field-label",
            }}
            value={value}
            label={label}
            disabled={disabled}
            control={<Radio checked={input.value === value} />}
          />
        ))}
    </RadioGroup>
    {meta.touched && meta.error && (
      <FormHelperText>{meta.error}</FormHelperText>
    )}
  </FormControl>
);

export const renderSelectField = ({
  input,
  label,
  options,
  meta: { touched, error },
  ...rest
}) => (
  <FormControl fullWidth {...rest}>
    <InputLabel variant="standard" error={Boolean(touched && error)}>
      {label}
    </InputLabel>
    <Select
      {...input}
      value={input.value}
      onChange={(event) => input.onChange(event.target.value)}
      displayEmpty
      style={{ textAlign: "left" }}
      variant="standard"
    >
      <MenuItem value={0} disabled>
        {label}
      </MenuItem>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled ? option.disabled : false}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
    {touched && error && <FormHelperText error>{error}</FormHelperText>}
  </FormControl>
);

export const renderAutocomplete = (props) => <AutoComplete {...props} />;
/*
export const renderDatePicker = ({ input, placeholder, name}) => {
  const [value, onChange] = useState(new Date());

  return (<div className='order_datepicker_div'>
      <DatePicker
            name={name}
            className="plus-icon"
            format="dd-MM-yyyy"
            value={value}
            onChange={(date)=> onChange(date)}
            disabledKeyboardNavigation
        />
  </div>)
  
};*/

export const renderDatePicker = ({
  input,
  label,
  meta: { touched, error },
}) => {
  return (
    <div className="order_datepicker_div">
      <label className={Boolean(touched && error) ? "error" : ""}>
        {label}
      </label>
      <DatePicker
        className={Boolean(touched && error) ? "error" : ""}
        {...input}
        dateFormat="MM/dd/yy"
        selected={
          input.value && input.value !== ""
            ? moment(input.value, "MM/DD/YYYY").utc().toDate()
            : ""
        }
        value={
          input.value && input.value !== ""
            ? moment(input.value, "MM/DD/YYYY").utc().toDate()
            : ""
        }
        onChange={(date) => {
          input.onChange(moment(date).format("MM/DD/YYYY"));
        }}
        placeholderText="MM/DD/YY"
        minDate={new Date()}
      />
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};
