import React from 'react';

export default () => (
    <React.Fragment>
        <h1>Política de Privacidad</h1>
        <p>
            <strong>
                Fecha de Vigencia:
            </strong>
            &nbsp;3 de septiembre de 2024
        </p>
        <ol>
            <li>
                <h3>
                    Introducción
                </h3>
                <p>
                    Bienvenido a SilverBack Home Warranty ("nosotros", "nos" o "nuestro"). Estamos comprometidos con la
                    protección de su privacidad y con asegurar que su información personal sea manejada de manera segura
                    y responsable. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos la
                    información que usted nos proporciona.
                </p>
            </li>
            <li>
                <h3>
                    Información que Recopilamos
                </h3>
                <p>
                    Recopilamos información personal que usted nos proporciona voluntariamente cuando se registra en
                    nuestro sitio web o se comunica con nosotros. Los tipos de información personal que podemos
                    recopilar incluyen:
                </p>
                <ul>
                    <li>
                        <strong>
                            Dirección de Correo Electrónico
                        </strong>
                    </li>
                    <li>
                        <strong>
                            Número de Teléfono
                        </strong>
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Uso de la Información
                </h3>
                <p>
                    Utilizamos la información que recopilamos para los siguientes propósitos:
                </p>
                <ul>
                    <li>
                        Para enviarle comunicaciones de marketing, incluyendo boletines, promociones y actualizaciones.
                    </li>
                    <li>
                        Para responder a sus consultas y proporcionar soporte al cliente.
                    </li>
                    <li>
                        Para cumplir con requisitos legales. No vendemos, intercambiamos ni transferimos de otro modo su
                        información personal a terceros.
                    </li>
                </ul>
                <p>
                    No vendemos, intercambiamos ni transferimos de otro modo su información personal a terceros.
                </p>
                <p>
                    La información móvil no será compartida con terceros o afiliados para fines de marketing/promoción.
                    Todas las categorías anteriores excluyen los datos de consentimiento de origen de mensajería de
                    texto; esta información no será compartida con terceros.
                </p>
            </li>
            <li>
                <h3>
                    Comunicaciones de Marketing
                </h3>
                <p>
                    Al proporcionar su dirección de correo electrónico y número de teléfono, usted acepta recibir
                    comunicaciones de marketing de nuestra parte. La frecuencia de los mensajes puede variar dependiendo
                    de su interacción con nuestras comunicaciones.
                </p>
            </li>
            <li>
                <h3>
                    Términos de Mensajería de Texto
                </h3>
                <ul>
                    <li>
                        La frecuencia de los mensajes varía según el usuario.
                    </li>
                    <li>
                        Pueden aplicarse tarifas de mensaje y datos.
                    </li>
                    <li>
                        Envíe un mensaje con la palabra AYUDA para obtener ayuda.
                    </li>
                    <li>
                        Envíe un mensaje con la palabra ALTO para cancelar la suscripción.
                    </li>
                    <li>
                        Los operadores no son responsables por mensajes retrasados o no entregados.
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Protección de Datos
                </h3>
                <p>
                    Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información
                    personal. Sin embargo, tenga en cuenta que ningún método de transmisión o almacenamiento de datos es
                    completamente seguro, y no podemos garantizar una seguridad absoluta.
                </p>
            </li>
            <li>
                <h3>
                    Sus Derechos
                </h3>
                <p>
                    Usted tiene el derecho de:
                </p>
                <ul>
                    <li>
                        Optar por no recibir comunicaciones de marketing siguiendo las instrucciones proporcionadas en
                        nuestras comunicaciones.
                    </li>
                    <li>
                        Solicitar acceso a la información personal que mantenemos sobre usted.
                    </li>
                    <li>
                        Solicitar correcciones a cualquier información inexacta o incompleta.
                    </li>
                    <li>
                        Solicitar la eliminación de su información personal, sujeto a ciertas excepciones.
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Cambios en Esta Política de Privacidad
                </h3>
                <p>
                    Podemos actualizar esta Política de Privacidad de vez en cuando. Cualquier cambio será publicado en
                    esta página con una fecha de revisión actualizada.
                </p>
            </li>
            <li>
                <h3>
                    Contáctenos
                </h3>
                <p>
                    Si tiene alguna pregunta sobre esta Política de Privacidad o nuestras prácticas, por favor
                    contáctenos en <a
                    href="mailto:info@silverbackhw.com">info@silverbackhw.com</a>
                </p>
            </li>
        </ol>
    </React.Fragment>
);
