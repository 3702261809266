import React from "react";
import { bool } from "prop-types";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { withTranslation } from "react-i18next";

import * as Validator from "helpers/validators";
import {
  renderTextField,
  renderRadioGroup,
  renderAutocomplete,
  renderSelectField,
} from "helpers/form";
import * as Styled from "./styled-components";
import theme from "helpers/theme";

const ContactInfo = ({
  change,
  t,
  agents,
  escrows,
  isListingCoverage,
  onBuyerAgentSearch,
  onSellerAgentSearch,
  onSearch,
  onEscrowSelect,
}) => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const keyDown = (evt) => {
    const theEvent = evt || window.event;
    const key = theEvent.keyCode || theEvent.which;
    const keyChar = String.fromCharCode(key);
    const regex = /^[0-9.,\b]+$/;
    if (
      !regex.test(keyChar) &&
      (key < 96 || key > 105) // key codes for numeric keypad
    ) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  const renderContact = (prefix, { t, validator, ...props }) => (
    <Styled.FormGroup>
      <Typography className="label" color="secondary" align="left" variant="h3">
        {t(`${prefix}Information`)}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            name={`${prefix}Name`}
            label={t("name")}
            component={renderTextField}
            validate={validator}
          />
        </Grid>
        {prefix === "buyer" ? (
          <Grid item xs={6}>
            <Field
              name={`${prefix}Phone`}
              label={t("phone")}
              onMouseOut={(event) => {
                if (event.target.value && event.target?.value.trim !== "") {
                  change(
                    `${prefix}Phone`,
                    formatPhoneNumber(event.target.value)
                  );
                }
              }}
              onKeyDown={(e) => keyDown(e)}
              onChange={(e) => {
                if (e.target.value && e.target?.value.length > 9) {
                  setTimeout(() => {
                    let number = formatPhoneNumber(e.target.value);
                    change(`${prefix}Phone`, number);
                  }, 700);
                }
              }}
              component={renderTextField}
              validate={Validator.isValidReqNumber}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Field
              name={`${prefix}Phone`}
              label={t("phone")}
              onMouseOut={(event) => {
                if (event.target.value && event.target?.value.trim !== "") {
                  change(
                    `${prefix}Phone`,
                    formatPhoneNumber(event.target.value)
                  );
                }
              }}
              onChange={(e) => {
                if (e.target.value && e.target?.value.length > 9) {
                  setTimeout(() => {
                    let number = formatPhoneNumber(e.target.value);
                    change(`${prefix}Phone`, number);
                  }, 700);
                }
              }}
              onKeyDown={(e) => keyDown(e)}
              component={renderTextField}
              validate={
                isListingCoverage
                  ? Validator.isValidReqNumber
                  : Validator.isValidNumber
              }
            />
          </Grid>
        )}
        {prefix === "buyer" ? (
          <Grid item xs={6}>
            <Field
              name={`${prefix}Email`}
              label={t("email")}
              component={renderTextField}
              validate={Validator.isEmail}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Field
              name={`${prefix}Email`}
              label={t("email")}
              component={renderTextField}
              validate={Validator.isValidEmail}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Field
            name={`${prefix}AgentName`}
            label={t("agentName")}
            items={props.items}
            itemToString={(option) =>
              option?.firstName
                ? `${option.firstName} ${option.lastName}`
                : option
            }
            itemToDisplayString={(option) =>
              option
                ? `${option.firstName} ${option.lastName}, ${option.company}`
                : ""
            }
            onSelect={props.onSearch}
            component={renderAutocomplete}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name={`${prefix}AgentCompany`}
            label={t("company")}
            component={renderTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`${prefix}AgentPhone`}
            label={t("agentPhone")}
            onMouseOut={(event) => {
              if (event.target.value && event.target?.value.trim !== "") {
                change(
                  `${prefix}AgentPhone`,
                  formatPhoneNumber(event.target.value)
                );
              }
            }}
            onChange={(e) => {
              if (e.target.value && e.target?.value.length > 9) {
                setTimeout(() => {
                  let number = formatPhoneNumber(e.target.value);
                  change(`${prefix}AgentPhone`, number);
                }, 700);
              }
            }}
            onKeyDown={(evt) => {
              const theEvent = evt || window.event;
              const key = theEvent.keyCode || theEvent.which;
              const keyChar = String.fromCharCode(key);
              const regex = /^[0-9.,\b]+$/;
              if (
                !regex.test(keyChar) &&
                (key < 96 || key > 105) // key codes for numeric keypad
              ) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
              }
              // var theEvent = evt || window.event;
              // var key = theEvent.keyCode || theEvent.which;
              // key = String.fromCharCode(key);
              // if (key.length === 0) return;
              // var regex = /^[0-9.,\b]+$/;
              // if (!regex.test(key)) {
              //   theEvent.returnValue = false;
              //   if (theEvent.preventDefault) theEvent.preventDefault();
              // }
            }}
            component={renderTextField}
            validate={Validator.numberLengthCheck}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`${prefix}AgentEmail`}
            label={t("agentEmail")}
            component={renderTextField}
            validate={Validator.isValidEmail}
          />
        </Grid>
      </Grid>
    </Styled.FormGroup>
  );

  const formatPhoneNumber = (phoneNumber) => {
    // var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    // var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    // console.log(match, "maaaaaaaaaatch++++");
    // if (match) {
    //   const value = ["(", match[2], ")", match[3], "-", match[4]].join("");
    //   return value;
    // }
    let phone = ("" + phoneNumber).replace(/\D/g, "");
    if (phone.length > 3) phone = phone.replace(/.{3}/, "($&)");
    if (phone.length > 9) phone = phone.replace(/.{8}/, "$&-");
    return phone;
  };

  const placedByOptions = [
    { value: "Homeowner", label: t("homeowner") },
    { value: "Listing Agent", label: t("listingAgent") },
    { value: "Buyer Agent", label: t("buyerAgent") },
    { value: "Escrow Officer", label: t("escrowOfficer") },
    { value: "Transaction Coordinator", label: t("transactionCoordinator") },
  ];

  const onEscrowChange = (fieldName, value) => {
    if (!value || value.length < 3) return;
    onSearch(fieldName, value);
  };
  return (
    <Styled.FormGroup>
      <Typography
        className="label"
        color="primary"
        align="left"
        variant="subheading"
        sx={{
          fontFamily: "'PT Sans Narrow', 'Roboto', 'sans-serif'",
          fontSize: "24px",
          [theme.breakpoints.down("md")]: {
            fontSize: "18px",
          },
        }}
      >
        {t("contactInformation")}
      </Typography>

      <Divider />

      {!isListingCoverage && width >= 600 && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Typography
              className="label"
              color="secondary"
              align="left"
              variant="caption"
            >
              {t("plans:placedBy")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Field
              name="placedBy"
              component={renderRadioGroup}
              validate={Validator.isRequired}
              className="flex-row"
              options={placedByOptions}
            />
          </Grid>
        </Grid>
      )}

      {!isListingCoverage && width < 600 && (
        <Grid item xs={12}>
          <Field
            name="placedBy"
            label={t("plans:placedBy")}
            component={renderSelectField}
            validate={Validator.isRequired}
            options={placedByOptions}
          />
        </Grid>
      )}
      <Grid container spacing={1}>
        {isListingCoverage ? null : (
          <Grid item xs={12} sm={6}>
            {renderContact("buyer", {
              t,
              validator: isListingCoverage ? null : Validator.isRequired,
              onSearch: onBuyerAgentSearch,
              items: agents,
            })}
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          {renderContact("seller", {
            t,
            validator: isListingCoverage ? Validator.isRequired : null,
            onSearch: onSellerAgentSearch,
            items: agents,
          })}
        </Grid>
        <Grid item xs={12}>
          <Field
            name="additionalEmails"
            label={t("plans:form.emails")}
            helperText={t("plans:form.emailHelper")}
            validate={Validator.isValidAdditionalEmail}
            component={renderTextField}
            style={{
              marginTop: 10,
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif"`,
            }}
          />
        </Grid>
             </Grid>
             <Grid item sm={6}>
             <Grid container spacing={1} style={{marginTop:'8px'}}>
   
                  <Typography
                    className="label"
                    color="secondary"
                    align="left"
                    variant="caption"
                    sx={{
                      fontSize: "22px !important",
                      lineHeight: "1.375em",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "18px !important" ,
                      },
                    }}
                  >
                    {t("plans:isSpanish")}
                  </Typography>
               
      <Grid item sm={5}>

                  <Field
                    name="Buyer_First_Language"
                    component={renderRadioGroup}
                    // validate={Validator.isRequired}
                    className="flex-row"
                    options={[{ value: "Spanish", label: "Yes" }, { value: "English", label: "No" }]}
                    label="Is English the Buyer's first language?"
                    style={{marginLeft:'8px'}}
      
              
                  />
                </Grid>
                </Grid>
                </Grid>
      {isListingCoverage ? null : (
        <Styled.FormGroup>
          <Typography
            className="label"
            color="secondary"
            align="left"
            variant="caption"
          >
            {t("titleEscrowInformation")}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Field
                name="escrowOfficer"
                label={t("escrowOfficer")}
                component={renderAutocomplete}
                onChange={(e, val) => {
                  onEscrowChange("titleOfficer", val);
                }}
                onSelect={onEscrowSelect}
                items={escrows}
                itemToString={(option) =>
                  option?.titleOfficer ? option.titleOfficer : option
                }
                itemToDisplayString={(option) =>
                  option
                    ? `${option?.titleOfficer}, ${option?.titleEscrow}`
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="escrowCompany"
                label={t("escrowCompany")}
                component={renderTextField}
                validate={isListingCoverage ? null : Validator.isRequired}
                onChange={(e, val) => onEscrowChange("titleEscrow", val)}
                // onSelect={onEscrowSelect}
                items={escrows}
                itemToString={(option) =>
                  option.titleEscrow ? option.titleEscrow : option
                }
                itemToDisplayString={(option) =>
                  option ? `${option?.titleOfficer}, ${option.titleEscrow}` : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="escrowPhone"
                label={t("phone")}
                validate={Validator.numberLengthCheck}
                // onMouseLeave={(event) => {
                //   let number = formatPhoneNumber(event.target.value);
                //   console.log(number, "iii))))))))))", event.target.value);
                //   change("escrowPhone", number);
                // }}
                onMouseOut={(event) => {
                  if (event.target.value && event.target?.value.trim !== "") {
                    change(
                      `escrowPhone`,
                      formatPhoneNumber(event.target.value)
                    );
                  }
                }}
                onChange={(e) => {
                  if (e.target.value && e.target?.value.length > 9) {
                    setTimeout(() => {
                      let number = formatPhoneNumber(e.target.value);
                      change("escrowPhone", number);
                    }, 700);
                  }
                }}
                onKeyDown={(e) => keyDown(e)}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="escrowEmail"
                label={t("email")}
                component={renderTextField}
                validate={
                  isListingCoverage
                    ? null
                    : Validator.isRequired && Validator.isEmail
                }
                onChange={(e, val) => onEscrowChange("titleEmail", val)}
                // onSelect={onEscrowSelect}
                items={escrows}
                itemToString={(option) =>
                  option.titleEmail ? option.titleEmail : option
                }
                itemToDisplayString={(option) =>
                  option ? `${option.titleEmail}, ${option?.titleOfficer}` : ""
                }
              />
            </Grid>
          </Grid>
        </Styled.FormGroup>
      )}
    </Styled.FormGroup>
  );
};

ContactInfo.propTypes = {
  isListingCoverage: bool.isRequired,
};

export default withTranslation(["common", "plans"])(ContactInfo);
