import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  ListItemText,
  ListItem,
  List,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import {showFormattedDate} from "../../../helpers/utils";

class ClaimDetails extends Component {
  renderClaims = () => {
    const { claims } = this.props;
    if (claims && claims.length > 0) {
      const claimIds = Object.keys(claims);

      return claimIds.map((id) => {
        const claim = claims[id];

        return (
          <Grid item xs={12} sm={6} key={id}>
            <Paper key={id} elevation={1} style={{ height: "100%" }}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Create Date"
                    secondary={
                      claim.Create_Date !== ""
                        ? showFormattedDate(claim.Create_Date)
                        : "---"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Claim Name"
                    secondary={claim.Name ? claim.Name : "---"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Claim Type"
                    secondary={claim.Claim_Type ? claim.Claim_Type : "---"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Claim Status"
                    secondary={claim.Status ? claim.Status : "---"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Contractor"
                    secondary={
                      claim.Contractor_Info ? claim.Contractor_Info.name : "---"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Contractor Email"
                    secondary={
                      claim.Contractor_Email ? claim.Contractor_Email : "---"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Customer Facing Description"
                    secondary={
                      claim.Customer_Facing_Description
                        ? claim.Customer_Facing_Description
                        : "---"
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12} sm={12}>
          <Paper elevation={0} style={{ height: "100%" }}>
            <List>
              <ListItem>
                <ListItemText primary="No Previous Claims to display" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      );
    }
  };

  render() {
    return (
      <Card>
        <CardHeader
          title="Claim Details"
          // action={
          //   <Link component="Link" variant="subheading" to="/claims">
          //     Request Service
          //   </Link>
          // }
        />
        <CardContent>
          <Grid container spacing={2}>
            {this.renderClaims()}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default ClaimDetails;
