import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import EmailService from "services/email";
import * as Styled from "../../claims/components/claim-confirmation/styled";
import {showFormattedDate} from "../../../helpers/utils";

function Review(props) {
  var user = props.user;
  var review = props.review;
  const [comments, setComments] = useState("");
  const [disable, setDisable] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  async function handleRequestOrderChange(values) {
    var data = await EmailService.requestOrderChange(values, user, review);
    if (data.data.status === "success") {
      setOpenDialog(true);
      setStatus("success");
      console.log("email sent successfully");
    } else {
      setOpenDialog(true);
      setStatus("failed");
      console.log("email has not sent");
    }
  }
  function handleClick() {
    console.log("working fine");
    navigate("/");
    window.gtag("event", "conversion", {
      send_to: "AW-779059110/iC1ACNuzsN4BEKb_vfMC",
      transaction_id: "",
    });
  }
  function handleChange(e) {
    if (e.target.value !== "") {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setComments(e.target.value);
  }
  var t = props.t;
  var cost = props.discount ? props.cost + +props.discount : props.cost;
  var optionCost = props.optionCost;
  var year = props.yearPlan;
  var discount = props.discount;
  console.log(cost, optionCost, year, discount, user);
  return (
    <Grid container className="mt-5">
      <Styled.Modal open={openDialog} onClose={handleClick}>
        <Styled.ModalContent>
          <Typography variant="h3" color="primary">
            {t("plans:order.thankYou")}
          </Typography>
          <Typography variant="subtitle">
            {status === "success"
              ? t("plans:order.successSubtitle")
              : t("plans:order.failedSubtitle")}
          </Typography>
          <Button
            onClick={handleClick}
            color="secondary"
            fullWidth
            variant="contained"
            style={{ marginTop: 20 }}
          >
            {t("goHome")}
          </Button>
        </Styled.ModalContent>
      </Styled.Modal>
      <Grid item xs={12} sm={6} style={{ marginTop: "10px", padding: "10px" }}>
        <Paper elevation={1} className="card_border">
          <Typography
            color="secondary"
            variant="subtitle"
            style={{ paddingLeft: "10px", paddingTop: "10px" }}
          >
            Buyer Details
          </Typography>
          <Grid container className="m-3">
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Buyer Name </div>
              <div className="sub_detail">
                {" "}
                {review.buyerName !== undefined ? review.buyerName : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Buyer Agent Name </div>
              <div className="sub_detail">
                {" "}
                {review.buyerAgentName !== undefined
                  ? review.buyerAgentName
                  : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Buyer Email </div>
              <div className="sub_detail">
                {" "}
                {review.buyerEmail !== undefined
                  ? review.buyerEmail
                  : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Buyer Agent Email </div>
              <div className="sub_detail">
                {" "}
                {review.buyerAgentEmail !== undefined
                  ? review.buyerAgentEmail
                  : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Buyer Phone </div>
              <div className="sub_detail">
                {" "}
                {review.buyerPhone !== undefined
                  ? review.buyerPhone
                  : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Buyer Agent Phone </div>
              <div className="sub_detail">
                {" "}
                {review.buyerAgentPhone !== undefined
                  ? review.buyerAgentPhone
                  : "---"}{" "}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} style={{ marginTop: "10px", padding: "10px" }}>
        <Paper elevation={1} className="card_border">
          <Typography
            color="secondary"
            variant="subtitle"
            style={{ paddingLeft: "10px", paddingTop: "10px" }}
          >
            Seller Details
          </Typography>
          <Grid container className="m-3">
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Seller Name </div>
              <div className="sub_detail">
                {" "}
                {review.sellerName !== undefined
                  ? review.sellerName
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Seller Agent Name </div>
              <div className="sub_detail">
                {" "}
                {review.sellerAgentName !== undefined
                  ? review.sellerAgentName
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Seller Email </div>
              <div className="sub_detail">
                {" "}
                {review.sellerEmail !== undefined
                  ? review.sellerEmail
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Seller Agent Email </div>
              <div className="sub_detail">
                {" "}
                {review.sellerAgentEmail !== undefined
                  ? review.sellerAgentEmail
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Seller Phone </div>
              <div className="sub_detail">
                {" "}
                {review.sellerPhone !== undefined
                  ? review.sellerPhone
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Seller Agent Phone </div>
              <div className="sub_detail">
                {" "}
                {review.sellerAgentPhone !== undefined
                  ? review.sellerAgentPhone
                  : "---"}{" "}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {review.escrowOfficer !== undefined ? (
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginTop: "10px", padding: "10px" }}
        >
          <Paper elevation={1}>
            <Typography
              color="secondary"
              variant="subtitle"
              style={{ paddingLeft: "10px", paddingTop: "10px" }}
            >
              Escrow Details
            </Typography>
            <Grid container className="m-3">
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Escrow Name </div>
                <div className="sub_detail">
                  {" "}
                  {review.escrowOfficer !== undefined
                    ? review.escrowOfficer
                    : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Escrow Email </div>
                <div className="sub_detail">
                  {" "}
                  {review.escrowEmail !== undefined
                    ? review.escrowEmail
                    : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Escrow Company </div>
                <div className="sub_detail">
                  {" "}
                  {review.escrowCompany !== undefined
                    ? review.escrowCompany
                    : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Escrow Phone </div>
                <div className="sub_detail">
                  {" "}
                  {review.escrowPhone !== undefined
                    ? review.escrowPhone
                    : "---"}{" "}
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={1} style={{ marginTop: "30px" }}>
            <Typography
              color="secondary"
              variant="subtitle"
              style={{ paddingLeft: "10px", paddingTop: "10px" }}
            >
              Property Details
            </Typography>
            <Grid container className="m-3">
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Street </div>
                <div className="sub_detail">
                  {" "}
                  {review.street !== undefined ? review.street : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Unit </div>
                <div className="sub_detail">
                  {" "}
                  {review.unit !== undefined ? review.unit : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> City </div>
                <div className="sub_detail">
                  {" "}
                  {review.city !== undefined ? review.city : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> State </div>
                <div className="sub_detail">
                  {" "}
                  {review.state !== undefined ? review.state : "---"}{" "}
                </div>
              </Grid>

              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Postal </div>
                <div className="sub_detail">
                  {" "}
                  {review.postal !== undefined ? review.postal : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> BigHouse </div>
                <div className="sub_detail">
                  {" "}
                  {review.bigHouse !== undefined ? review.bigHouse : "---"}{" "}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginTop: "10px", padding: "10px" }}
        >
          <Paper elevation={1} style={{ height: "100%" }}>
            <Typography
              color="secondary"
              variant="subtitle"
              style={{ paddingLeft: "10px", paddingTop: "10px" }}
            >
              Property Details
            </Typography>
            <Grid container className="m-3">
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Street </div>
                <div className="sub_detail">
                  {" "}
                  {review.street !== undefined ? review.street : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Unit </div>
                <div className="sub_detail">
                  {" "}
                  {review.unit !== undefined ? review.unit : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> City </div>
                <div className="sub_detail">
                  {" "}
                  {review.city !== undefined ? review.city : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> State </div>
                <div className="sub_detail">
                  {" "}
                  {review.state !== undefined ? review.state : "---"}{" "}
                </div>
              </Grid>

              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> Postal </div>
                <div className="sub_detail">
                  {" "}
                  {review.postal !== undefined ? review.postal : "---"}{" "}
                </div>
              </Grid>
              <Grid xs={6} sm={6} className="content_div">
                <div className="sub_headng"> BigHouse </div>
                <div className="sub_detail">
                  {" "}
                  {review.bigHouse !== undefined ? review.bigHouse : "---"}{" "}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} sm={6} style={{ marginTop: "10px", padding: "10px" }}>
        <Paper elevation={1} style={{ height: "100%" }}>
          <Typography
            color="secondary"
            variant="subtitle"
            style={{ paddingLeft: "10px", paddingTop: "10px" }}
          >
            Plan Details
          </Typography>
          <Grid container className="m-3">
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Placed By </div>
              <div className="sub_detail">
                {" "}
                {review.placedBy !== undefined ? review.placedBy : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Plan Type </div>
              <div className="sub_detail">
                {" "}
                {review.planOption !== undefined
                  ? review.planOption
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Area Manager </div>
              <div className="sub_detail">
                {" "}
                {review.areaManager !== undefined
                  ? review.areaManager
                  : "---"}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Closing Date </div>
              <div className="sub_detail">
                {" "}
                {review.closingDate
                  ? showFormattedDate(review.closingDate)
                  : "---"}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} md={4} className="content_div">
              <div className="sub_headng"> Plan Cost </div>
              <div className="sub_detail">
                {" "}
                {`$${cost !== undefined ? cost : "---"}`}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} md={4} className="content_div">
              <div className="sub_headng"> Option Cost </div>
              <div className="sub_detail">
                {" "}
                {`$${optionCost !== undefined ? optionCost : "---"}`}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} md={4} className="content_div">
              <div className="sub_headng"> Discount </div>
              <div className="sub_detail">
                {" "}
                {`$${discount !== undefined ? discount : "---"}`}{" "}
              </div>
            </Grid>

            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Total Cost </div>
              <div className="sub_detail">
                {" "}
                {`$${
                  cost !== undefined ? cost + optionCost - discount : "---"
                }`}{" "}
              </div>
            </Grid>
            <Grid xs={6} sm={6} className="content_div">
              <div className="sub_headng"> Number of Years </div>
              <div className="sub_detail">
                {" "}
                {year !== undefined ? year : "---"}{" "}
              </div>
            </Grid>
          </Grid>
          <List>
            {review.addons !== undefined ? (
              <ListItem>
                <ListItemText
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: "700",
                      fontFamily: `'PT Sans Narrow', 'Roboto', 'sans-serif'`,
                      lineHeight: "1.5em",
                    },
                    padding: "4px 6px",
                  }}
                  primary="Addons"
                  className="sub_headng"
                  secondary={review.addons.map((addon, i) => {
                    if (i !== review.addons.length - 1) {
                      return addon + ", ";
                    } else {
                      return addon;
                    }
                  })}
                />
              </ListItem>
            ) : (
              ""
            )}
          </List>
        </Paper>
      </Grid>
      <TextField
        fullWidth
        style={{ marginTop: "20px" }}
        multiline
        rows={9}
        value={comments}
        placeholder="If something needs updating or is incorrect, please input it here and we'll get right on it."
        name="comments"
        onChange={(e) => handleChange(e)}
      />
      <Grid item xs={6} style={{ paddingLeft: "20px" }}>
        <Button
          disabled={disable}
          type="submit"
          onClick={() => handleRequestOrderChange(comments)}
          color="primary"
          variant="contained"
          style={{ marginTop: 20, width: "90%" }}
        >
          Send
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={handleClick}
          color="secondary"
          variant="contained"
          style={{ marginTop: 20, width: "90%" }}
        >
          {t("goHome")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Review;
