import moment from "moment/moment";
import {
  PlanPriceEnum,
  KING_KONG,
  SILVERBACK,
  BLACKBACK,
} from "../constants/plans";
import { AddonPriceEnum } from "../constants/addons";

export default class Utils {
  static getPlanAmount = (plan = "listing-coverage") => {
    return PlanPriceEnum[plan] || 0;
  };

  static getAddonAmount = (addon) => {
    return AddonPriceEnum[addon] || 0;
  };

  static getAddonTotal = (addons, order) => {
    if (!Array.isArray(addons)) return 0;
    if (order !== undefined) {
      if (order.sf_2years !== undefined || order.mf_2years !== undefined) {
        return addons
          .map((addon) =>
            addon !== "Pre-paid Service Call Fee"
              ? order.sf_2years || order.mf_2years
                ? AddonPriceEnum[addon] * 2 || 0
                : AddonPriceEnum[addon] || 0
              : AddonPriceEnum[addon] || 0
          )
          .reduce((total, addon) => total + addon, 0);
      } else {
        return addons
          .map((addon) => AddonPriceEnum[addon] || 0)
          .reduce((total, addon) => total + addon, 0);
      }
    } else {
      return addons
        .map((addon) => AddonPriceEnum[addon] || 0)
        .reduce((total, addon) => total + addon, 0);
    }
  };

  static findPlanMatch = (amount) => {
    if (amount >= PlanPriceEnum[KING_KONG]) return KING_KONG;

    if (amount >= PlanPriceEnum[SILVERBACK]) return SILVERBACK;

    return BLACKBACK;
  };

  static smoothScrollToTop = (_) => {
    const currentOffset = window.scrollY;
    if (currentOffset) {
      window.scroll(0, currentOffset - 100);
      Utils.smoothScrollToTop.fn = requestAnimationFrame(
        Utils.smoothScrollToTop
      );
    } else {
      cancelAnimationFrame(Utils.smoothScrollToTop.fn);
      Utils.smoothScrollToTop.fn = null;
    }
  };

  static createProjection = (objToCopy = {}, fields = []) => {
    const obj = {};
    fields.forEach((field) => {
      if (Array.isArray(field)) {
        if (field[2]) {
          // pass 3rd element in array for rename field
          obj[field[2]] = objToCopy[field[0]] ? objToCopy[field[0]] : field[1];
        } else {
          obj[field[0]] = objToCopy[field[0]] ? objToCopy[field[0]] : field[1];
        }
      } else {
        if (objToCopy[field]) {
          obj[field] = objToCopy[field];
        }
      }
    });
    return obj;
  };
}

// Set the access token as a cookie
export function setAccessTokenCookie(resp) {
  // access_tokenPromise.then((resp) => {
  const date = new Date();
  date.setTime(date.getTime() + resp.expires_in * 1000 - 3000);
  const expires = "expires=" + date.toUTCString();
  if (resp.access_token)
    document.cookie =
      "access_token=" + resp.access_token + ";" + expires + ";path=/";
  // });
}

// Get the access token from the cookie
export function getAccessTokenCookie() {
  const name = "access_token=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const value = c.substring(name.length, c.length);
      if (value.length < 5) {
        return;
      }
      return value;
    }
  }
  return "";
}

export function clearToken() {
  document.cookie =
    "access_token='';expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; path=/";
}

export function showFormattedDate(_date, _format="MM/DD/YY") {
  return moment(_date).format(_format)
}

export function getUtcDate(_format="MM/DD/YY") {
  return moment.utc().format(_format)
}